import Navbar from './components/Navbar';
import AOS from 'aos';
import HomePage from './container/HomePage';
import 'aos/dist/aos.css';
import './App.scss';

function App() {
  AOS.init();
  return (
    <>
      <Navbar />
      <HomePage />
    </>
  );
}

export default App;